<template>
  <div v-if="showEquipamento" class="auth-content">
    <Processamento :processando="overlay"></Processamento>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md2>
            <v-card class="elevation-12 pa-0">
              <v-toolbar dark color="primary">
                <v-icon id="icone">mdi-login</v-icon>

                <v-toolbar-title>Login Usuários </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pb-3">
                <v-form v-model="validInicial">
                  <div class="auth-logo">
                    <v-img src="@/assets/logidoc.png" width="200" alt="200" />
                  </div>
                  <v-row>
                    <v-col cols="12" class="pa-3">
                      <v-select
                        v-model="empresaSelecionada.cnpj"
                        :hint="` CNPJ ${empresaSelecionada.cnpj} `"
                        :items="empresas"
                        item-text="razao_social"
                        item-value="cnpj"
                        label="Selecione a Empresa"
                        hide-details="auto"
                        v-on:change="changeEmpresa"
                        outlined
                        persistent-hint
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pa-3">
                      <v-text-field
                        v-model="user.codigo"
                        name="Codigo"
                        label="Código Usuário"
                        required
                        outlined
                        placeholder=" "
                        persistent-placeholder
                        :rules="rulesCodigo"
                        hide-details="auto"
                        autocomplete="user.codigo"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pa-3">
                      <v-text-field
                        v-model="user.senha"
                        label="Senha"
                        name="password"
                        required
                        outlined
                        type="password"
                        placeholder=" "
                        persistent-placeholder
                        hide-details="auto"
                        autocomplete="user.senha"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pa-3">
                      <v-checkbox
                        v-model="checkboxSalvaSenha"
                        label="Salvar Senha "
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-4 py-0 pb-1">
                <v-spacer></v-spacer>
                <v-btn color="primary" v-if="showSignup" @click="signup"
                  >Registrar</v-btn
                >
                <v-btn
                  color="primary"
                  :disabled="!validInicial"
                  v-else
                  @click="signin"
                  >Entrar</v-btn
                >
              </v-card-actions>
              <div class="d-flex justify-center mb-6">
                <a
                  href
                  @click.prevent="vaiFormularioVerificacaoEquipamentoEmail"
                >
                  <span v-if="!showEquipamento"
                    >Já tem cadastro? Acesse o Login!</span
                  >
                  <span v-else
                    >Não tem cadastro ou equipamento autorizado? Registre-se
                    aqui!</span
                  >
                </a>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
  <div v-else>
    <div v-if="verificaSituacao">
      <v-main>
        <v-container fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md2>
              <v-card class="elevation-12 pa-0">
                <v-toolbar dark color="primary">
                  <v-icon id="icone">mdi-login</v-icon>

                  <v-toolbar-title>Registro Equipamento</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pb-3">
                  <v-form v-model="valid">
                    <div class="auth-logo">
                      <v-img src="@/assets/logidoc.png" width="200" alt="200" />
                    </div>
                    <v-row>
                      <v-col cols="12" class="pa-3">
                        <v-card dark color="primary" elevation="5" outlined>
                          <v-card-text>
                            Por favor digitar os campos abaixo para verificar
                            autorização de acesso do equipamento ! <br />
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="pa-3">
                        <v-text-field
                          v-model="user.cnpj"
                          name="cnpj"
                          label="CNPJ da Empresa"
                          required
                          outlined
                          :rules="rulesCNPJ"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="pa-3">
                        <v-text-field
                          v-model="user.codigo"
                          name="codigo"
                          label="Codigo Usuário"
                          required
                          outlined
                          :rules="rulesCodigo"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="pa-3">
                        <v-text-field
                          v-model="user.password"
                          label="Senha Usuário"
                          name="password"
                          required
                          outlined
                          type="password"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row v-if="!verifica">
                      <v-col cols="12" class="pa-3">
                        <div
                          id="inner"
                          class="drawing"
                          ref="drawing"
                          v-html="imagem"
                        ></div>
                      </v-col>
                    </v-row>

                    <v-row v-if="!verifica">
                      <v-col cols="12" class="pa-3">
                        <v-text-field
                          v-model="captcha"
                          name="captcha"
                          label="Digite o captcha"
                          required
                          outlined
                          :rules="rules"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions class="px-4 py-0 pb-1">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="registraEquipamentoEmpresaUsuario"
                    :disabled="!valid"
                    >Registra</v-btn
                  >
                </v-card-actions>
                <div class="d-flex justify-center mb-6">
                  <a href @click.prevent="voltaFormularioLogin">
                    <span v-if="!showEquipamento"
                      >Já tem cadastro? Acesse o Login!</span
                    >
                    <span v-else>Não tem cadastro? Registre-se aqui!</span>
                  </a>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </div>
    <div v-else>
      <v-main>
        <v-container fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md2>
              <v-card class="elevation-12 pa-0">
                <v-toolbar dark color="primary">
                  <v-icon id="icone">mdi-login</v-icon>

                  <v-toolbar-title>Registro Equipamento</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pb-3">
                  <v-form v-model="valid">
                    <div class="auth-logo">
                      <v-img src="@/assets/logidoc.png" width="200" alt="200" />
                    </div>
                    <v-row v-if="!verifica">
                      <v-col cols="12" class="pa-3">
                        <v-card dark color="primary" elevation="5" outlined>
                          <v-card-text v-if="cadastradoUUID">
                            Equipamento não autorizado! <br />
                            Por favor digite os dados abaixo para registro.
                          </v-card-text>
                          <v-card-text v-else>
                            Equipamento autorizado! <br />
                            Por favor digite os dados abaixo para cadastro do
                            usuário.
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col cols="12" class="pa-3">
                        <v-card dark color="primary" elevation="5" outlined>
                          <v-card-text>
                            Equipamento aguardando confirmação do email enviado
                            para o administrador! <br />
                            Tempo de validade da solicitação : {{ timerCount }}
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <div v-if="!cadastradoUUID">
                      <v-row>
                        <v-col cols="12" class="pa-3" v-if="!verifica">
                          <v-text-field
                            ref="textName"
                            v-model="user.name"
                            label="Nome"
                            required
                            outlined
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pa-3">
                          <v-text-field
                            v-model="user.email"
                            name="email"
                            label="E-mail"
                            required
                            outlined
                            :rules="rulesEmail"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pa-3" v-if="!verifica">
                          <v-text-field
                            v-model="user.password"
                            label="Senha"
                            name="password"
                            required
                            outlined
                            type="password"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pa-3" v-if="!verifica">
                          <v-text-field
                            v-model="user.confirmPassword"
                            label="Confirme a Senha"
                            required
                            outlined
                            type="password"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-row>
                        <v-col cols="12" class="pa-3">
                          <v-text-field
                            v-model="email"
                            name="email"
                            label="E-mail"
                            required
                            outlined
                            :rules="rulesEmail"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <v-row v-if="!verifica">
                      <v-col cols="12" class="pa-3">
                        <div
                          id="inner"
                          class="drawing"
                          ref="drawing"
                          v-html="imagem"
                        ></div>
                      </v-col>
                    </v-row>
                    <v-row v-if="!verifica">
                      <v-col cols="12" class="pa-3">
                        <v-text-field
                          v-model="captcha"
                          name="captcha"
                          label="Digite o captcha"
                          required
                          outlined
                          :rules="rules"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions class="px-4 py-0 pb-1">
                  <v-spacer></v-spacer>
                  <div v-if="!cadastradoUUID">
                    <v-btn
                      v-if="!verifica"
                      color="primary"
                      @click="registroUsuario"
                      :disabled="!valid"
                      >Cadastra</v-btn
                    >
                    <v-btn v-else color="primary" :disabled="false"
                      >Verifica</v-btn
                    >
                  </div>
                  <div v-else>
                    <v-btn
                      v-if="!verifica"
                      color="primary"
                      @click="registroEquipamento"
                      :disabled="!valid"
                      >Registrar</v-btn
                    >
                    <v-btn v-else color="primary" :disabled="false"
                      >Verifica</v-btn
                    >
                  </div>

                  <!-- @click="verificaEquipamento" -->
                </v-card-actions>
                <div class="d-flex justify-center mb-6">
                  <a href @click.prevent="voltaFormularioLogin">
                    <span v-if="!showEquipamento"
                      >Já tem cadastro? Acesse o Login!</span
                    >
                    <span v-else>Não tem cadastro? Registre-se aqui!</span>
                  </a>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </div>
  </div>
</template>

<script>
import {
  baseApiUrl,
  showError,
  userKey,
  userDashboard,
  equipamentoDashboard,
  showSucess,
} from "@/global";
import axios from "axios";
import { mapMutations } from "vuex";
import { DeviceUUID } from "device-uuid";
import { nanoid } from "nanoid";
import Processamento from "@/components/Processamento.vue";
import CryptoJS from "crypto-js"; //Encryption js

export default {
  name: "adminAuth",
  components: { Processamento },
  data: function() {
    return {
      valid: false,
      validInicial: false,
      checkboxSalvaSenha: false,

      rules: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length == 4) || "Digite 4 caracteres",
      ],

      rulesCodigo: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length <= 6) || "Digite no máximo 6 digitos",
        (value) =>
          (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999) ||
          "Digito inválido. Somente números",
      ],

      rulesCNPJ: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length == 14) || "Tamanho de 14 caracteres",
        (value) => (value && value.length == 14) || "Digito inválido",
        (value) =>
          (!isNaN(parseFloat(value)) &&
            value >= 0 &&
            value <= 99999999999999) ||
          "Digito inválido. Somente números",
      ],

      rulesEmail: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
        (value) => this.validEmail(value) || "Utilize um e-mail válido.",
      ],
      showSignup: false,
      user: {},
      uuid: "",
      uuid_new: "",
      equipamentos: [],
      imagem: "",
      empresas: [],
      empresaSelecionada: {},
      cnpjInicial: "",
      overlay: false,
      secret: "",
      captcha: "",
      cnpj: "",
      cookieID: "",
      showEquipamento: true,
      verifica: false,
      timerCount: 0,
      timerVerifica: 10,
      testaEmailUUId: false,
      cadastradoUsuario: false,
      cadastradoUUID: false,
      cadastroConfirmacao: false,
      verificaSituacao: true,
    };
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
            this.timerVerifica--;
            if (this.timerVerifica == 0) {
              console.log("verifica " + this.timerCount);
              this.timerVerifica = 10;
            }
          }, 1000);
        } else {
          this.verifica = false;
          this.Getcaptcha();
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    ...mapMutations({
      setAuthStatus: "setAuthStatus",
    }),
    async geraUUID() {
      var newuuid = await new DeviceUUID().get();
      this.uuid = newuuid;
      return this.uuid;
    },

    changeEmpresa(a) {
      let empresa2 = {};

      this.empresas.forEach(function(object) {
        if (object.cnpj == a) {
          empresa2.cnpj = object.cnpj;
          empresa2.razao_social = object.razao_social;
        }
      });

      this.empresaSelecionada = empresa2;
    },

    setLocalStoreUUID(uuid) {
      localStorage.setItem(equipamentoDashboard, uuid);
    },

    setLocalStore(user, password, cnpj) {
      let senhaCrypt = CryptoJS.AES.encrypt(
        password,
        "secret key 123"
      ).toString(); //Use CryptoJS method to encrypt
      let dadosLogin = {};
      dadosLogin["codigo"] = user;
      dadosLogin["cnpj"] = cnpj;
      dadosLogin["senha"] = senhaCrypt;
      // dadosLogin['senha'] = password;
      if (this.checkboxSalvaSenha) {
        localStorage.setItem(userDashboard, JSON.stringify(dadosLogin));
      } else {
        localStorage.removeItem(userDashboard);
      }
    },

    setCookie(user, password, days) {
      var text = CryptoJS.AES.encrypt(password, "secret key 123"); //Use CryptoJS method to encrypt
      var saveDays = new Date(); //Get Time
      saveDays.setTime(saveDays.getTime() + 24 * 60 * 60 * 1000 * days); //Number of days saved
      //String splicing and storing in cookie
      window.document.cookie =
        "user" + "=" + user + ";path=/;saveDays=" + saveDays.toGMTString();
      window.document.cookie =
        "password" + "=" + text + ";path=/;saveDays=" + saveDays.toGMTString();
    },
    getLocalStoreUUID() {
      try {
        this.uuid_new = localStorage.getItem(equipamentoDashboard);
        return this.uuid_new;
      } catch (error) {
        return "";
      }
    },
    getLocalStore() {
      let json = JSON.parse(localStorage.getItem(userDashboard));

      if (json) {
        var bytes = CryptoJS.AES.decrypt(json.senha, "secret key 123");
        var plaintext = bytes.toString(CryptoJS.enc.Utf8); //Get the decrypted password (the password entered when logging in)

        this.user.codigo = json.codigo;
        this.user.senha = plaintext;
        if (json.cnpj) {
          this.cnpjInicial = json.cnpj;
        }
        if (this.user.senha.length > 0) {
          this.checkboxSalvaSenha = true;
        }
      }
    },
    getCookie() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; "); //The format shown here needs to be cut and can be output by yourself.
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("="); //Cut again
          //This will cut out the array with mobile as the 0th item and the array with password as the 0th item, and judge the corresponding value
          if (arr2[0] == "user") {
            this.user.codigo = arr2[1]; //Get account
          } else if (arr2[0] == "password") {
            //Get the encrypted password arr2[1] and decrypt it
            var bytes = CryptoJS.AES.decrypt(
              arr2[1].toString(),
              "secret key 123"
            );
            var plaintext = bytes.toString(CryptoJS.enc.Utf8); //Get the decrypted password (the password entered when logging in)
            this.user.senha = plaintext;
          }
        }
      }
    },

    vaiFormularioVerificacaoEquipamentoEmail() {
      if (this.uuid_new == null || this.uuid_new == "") {
        this.uuid_new = nanoid();
        this.uuid = this.uuid_new;
        this.setLocalStoreUUID(this.uuid);
      }
      this.showEquipamento = false;
      this.verificaSituacao = true;
    },
    voltaFormularioLogin() {
      this.showEquipamento = true;
    },

    async verificaUUID() {
      this.overlay = true;

      var self = this;

      await axios
        .get(
          `${baseApiUrl}/dashboard_empresa/equipamentos?equipamento=${this.uuid}`
        )
        .then((res) => {
          this.empresas = res.data;

          this.getLocalStore();

          if (this.empresas[0] && this.empresas[0].cnpj) {
            if (!this.cnpjInicial) {
              this.empresaSelecionada.cnpj = this.empresas[0].cnpj;
              this.empresaSelecionada.razao_social = this.empresas[0].razao_social;
              console.log("Element is undefined");
            } else {
              let empresa2 = {};
              this.empresaSelecionada.cnpj = this.empresas[0].cnpj;
              this.empresaSelecionada.razao_social = this.empresas[0].razao_social;
              let cnpjInicial2 = this.cnpjInicial;
              this.empresas.forEach(function(object) {
                if (object.cnpj == cnpjInicial2) {
                  empresa2.cnpj = object.cnpj;
                  empresa2.razao_social = object.razao_social;
                }
              });
              if (empresa2) {
                this.empresaSelecionada = empresa2;
              }
            }

            console.log("Pode cadastrar !");
          } else {
            this.vaiFormularioVerificacaoEquipamentoEmail();
          }
        })
        .catch(() => {
          showError("Erro na consumo do API");
        })
        .finally(() => {
          self.overlay = false;
        });

      console.log("Pode cadastrar !");
    },

    async verificaUUID_v2() {
      this.overlay = true;

      var self = this;

      let equipamento = "";

      if (this.uuid_new != "") {
        equipamento = this.uuid_new;
      } else {
        equipamento = this.uuid;
      }

      await axios
        .get(
          `${baseApiUrl}/dashboard_empresa/equipamentos?equipamento=${equipamento}`
        )
        .then((res) => {
          this.empresas = res.data;

          this.getLocalStore();

          if (this.empresas[0] && this.empresas[0].cnpj) {
            if (!this.cnpjInicial) {
              this.empresaSelecionada.cnpj = this.empresas[0].cnpj;
              this.empresaSelecionada.razao_social = this.empresas[0].razao_social;
              console.log("Element is undefined");
            } else {
              let empresa2 = {};
              this.empresaSelecionada.cnpj = this.empresas[0].cnpj;
              this.empresaSelecionada.razao_social = this.empresas[0].razao_social;
              let cnpjInicial2 = this.cnpjInicial;
              this.empresas.forEach(function(object) {
                if (object.cnpj == cnpjInicial2) {
                  empresa2.cnpj = object.cnpj;
                  empresa2.razao_social = object.razao_social;
                }
              });
              if (empresa2) {
                this.empresaSelecionada = empresa2;
              }
            }

            console.log("Pode cadastrar !");
          } else {
            this.vaiFormularioVerificacaoEquipamentoEmail();
          }
        })
        .catch(() => {
          showError("Erro na consumo do API");
        })
        .finally(() => {
          self.overlay = false;
        });

      console.log("Pode cadastrar !");
    },

    async verificaUUID_Email() {
      await axios
        .post(
          `${baseApiUrl}/dashboard_empresa/equipamentos?equipamento=${this.uuid}&email=${this.user.email}`
        )
        .then((res) => {
          this.equipamentos = res.data;
          if (this.equipamentos.length == 0) {
            this.showEquipamento = false;
            this.email = this.user.email;
            this.Getcaptcha();
            this.testaEmailUUId = false;
          } else {
            if (this.equipamentos[0].status == 0) {
              this.showEquipamento = false;
              this.verifica = true;
              this.email = this.user.email;
              var newDate = new Date(this.equipamentos[0].date_expired);
              this.timerCount = Math.trunc((newDate - Date.now()) / 1000);
              this.testaEmailUUId = false;
            } else {
              this.testaEmailUUId = true;
            }
          }
        });
    },

    async Getcaptcha() {
      await axios.get(`${baseApiUrl}/captcha`).then((res) => {
        this.imagem = res.data.captcha;
        this.secret = res.data.secret;

        // if (this.imagem.length == 0) {

        // } else {

        // }
      });
    },
    async signin() {
      this.overlay = true;
      this.user["uuid"] = this.uuid;
      this.user["cnpj"] = this.empresaSelecionada.cnpj;

      await axios
        .post(`${baseApiUrl}/signin`, this.user)
        .then((res) => {
          this.$store.commit("setUser", res.data);

          localStorage.setItem(userKey, JSON.stringify(res.data));

          this.setAuthStatus(true);
          // this.setCookie(this.user.codigo, this.user.senha, 365);
          this.setLocalStore(this.user.codigo, this.user.senha, this.user.cnpj);

          if (this.uuid_new == null || this.uuid_new == "") {
            this.uuid_new = nanoid();
            this.user["uuid_new"] = this.uuid_new;
            axios.post(`${baseApiUrl}/ajustaNewUUID`, this.user).then((res) => {
              if (res.data.codigo == 1) {
                this.setLocalStoreUUID(this.uuid_new);
              }
            });
          }

          // this.$router.push({ path: "/pages/home" });
          this.$router.push({ path: "/" });
          this.overlay = false;
        })
        .catch((error) => {
          showError(error);
          this.overlay = false;
        });
    },
    signup() {
      axios
        .post(`${baseApiUrl}/signup`, this.user)
        .then(() => {
          showSucess();
          this.user = {};
          this.showSignup = false;
        })
        .catch(showError);
    },

    async registraEquipamentoEmpresaUsuario() {
      this.cadastradoUUID = false;
      this.cadastradoUsuario = false;
      this.cadastroConfirmacao = false;

      let objetoJson = {};
      objetoJson["captcha"] = this.captcha;
      objetoJson["secret"] = this.secret;
      objetoJson["uuid"] = this.uuid;
      objetoJson["user"] = this.user;

      await axios
        .post(
          `${baseApiUrl}/dashboard_empresa/registro_equipamentos_empresas_usuarios`,
          objetoJson
        )

        .then((res) => {
          this.equipamentos = res.data;
          console.log(this.equipamentos);
          if (this.equipamentos.codigo <= 0) {
            showError(this.equipamentos.message);
            //   this.showEquipamento = false;
            this.Getcaptcha();
            //   this.cadastradoUUID = false;
            //   this.testaEmailUUId = false;
          } else {
            if (this.equipamentos.message) {
              showSucess(this.equipamentos.message);
            }
            //   if (this.equipamentos.equipamentos.length == 0) {
            //     this.cadastradoUUID = false;
            //   } else {
            //     if (this.equipamentos.equipamentos[0].status == 0) {
            //       console.log(this.equipamentos.equipamentos[0].date_expired);
            //       if (
            //         this.equipamentos.equipamentos[0].date_expired >= Date.now()
            //       ) {
            //         this.cadastroConfirmacao = false;
            //       } else {
            //         this.cadastradoUUID = false;
            //       }
            //     } else {
            //       this.cadastradoUUID = false;
            //     }
            //   }
            //   if (this.equipamentos.email == "") {
            //     this.cadastradoUsuario = false;
            //   } else {
            //     this.cadastradoUsuario = false;
            //   }
            //   this.verificaSituacao = false;
            //   this.Getcaptcha();
            //   this.captcha = "";
          }
        })
        .catch((error) => {
          showError(error);
        });

      // if (this.showEquipamento) {
      //   this.showEquipamento = false;
      // } else {
      //   await axios
      //     .get(`${baseApiUrl}/?email=${this.user.email}`)
      //     .then((res) => {
      //       if (res.data.length > 0) {
      //         this.cadastradoUsuario = false;
      //       }
      //     });

      //   await axios
      //     .get(
      //       `${baseApiUrl}/dashboard_empresa/equipamentos?equipamento=${this.uuid}&email=${this.user.email}`
      //     )
      //     .then((res) => {
      //       this.equipamentos = res.data;
      //       if (this.equipamentos.length == 0) {
      //         this.showEquipamento = false;
      //         this.Getcaptcha();
      //         this.cadastradoUUID = false;
      //         this.testaEmailUUId = false;
      //       } else {
      //         if (this.equipamentos[0].status == 0) {
      //           this.showEquipamento = false;
      //           this.verifica = false;
      //           this.cadastroConfirmacao = false;
      //           var newDate = new Date(this.equipamentos[0].date_expired);
      //           this.timerCount = Math.trunc((newDate - Date.now()) / 1000);
      //           this.testaEmailUUId = false;
      //         } else {
      //           this.testaEmailUUId = false;
      //         }
      //       }
      //     })
      //     .catch(showError);

      //   if (this.verifica) {
      //     console.log("equipamento já existe");
      //   } else {
      //     console.log("equipamento não existe");
      //   }

      //   this.showEquipamento = false;
      // }
    },
    async registroUsuario() {
      let objetoJson = {};
      objetoJson["user"] = this.user;
      objetoJson["captcha"] = this.captcha;
      objetoJson["secret"] = this.secret;
      objetoJson["uuid"] = this.uuid;
      // showSucess("Registro enviado com sucesso!");

      // let config = {
      //   headers: {
      //     Cookie: "connect.sid=s%3AyR2lZb7UtP3D1cW_fVrsLp_8IHTrh9Ec.wGp%2FUAxWPoQzeGC8ooAb9gN%2F0KkbHGZiVBIPPmiJ4Y0",
      //   },
      // };

      await axios
        .post(`${baseApiUrl}/verify-captcha-usuario`, objetoJson)
        .then((res) => {
          if (res.data.codigo > 0) {
            showSucess(res.data.message);
            this.verifica = true;
          } else {
            var msgError = "";
            msgError = res.data.message;
            showError(msgError);
            this.limpaForm();
          }
        })
        .catch(showError);
    },

    async registroEquipamento() {
      let objetoJson = {};
      objetoJson["email"] = this.email;
      objetoJson["captcha"] = this.captcha;
      objetoJson["secret"] = this.secret;
      objetoJson["uuid"] = this.uuid;
      // showSucess("Registro enviado com sucesso!");

      // let config = {
      //   headers: {
      //     Cookie: "connect.sid=s%3AyR2lZb7UtP3D1cW_fVrsLp_8IHTrh9Ec.wGp%2FUAxWPoQzeGC8ooAb9gN%2F0KkbHGZiVBIPPmiJ4Y0",
      //   },
      // };

      await axios
        .post(`${baseApiUrl}/verify-captcha`, objetoJson)
        .then((res) => {
          if (res.data.codigo > 0) {
            showSucess(res.data.message);
            this.verifica = true;
          } else {
            showError(res.data.message);
            this.limpaForm();
          }
        })
        .catch(showError);
    },
    limpaForm() {
      this.Getcaptcha();
      this.captcha = "";
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(email);
    },
  },
  //  async created() {
  async created() {
    await this.getLocalStoreUUID();
    if (this.uuid_new == null || this.uuid_new == "") {
      this.uuid = await this.geraUUID();
      this.verificaUUID();
    } else {
      this.uuid = this.uuid_new;
      this.verificaUUID();
    }
  },
};
</script>

<style>
.auth-content {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-modal {
  background-color: #fff;
  width: 350px;
  padding: 35px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;
  align-items: center;
}

#icone {
  padding: 10px;
}

#inner {
  display: table;
  margin: 0 auto;
  border: 1px solid black;
}
.auth-logo {
  background-color: #fff;

  display: flex;
  flex-direction: column;

  align-items: center;
}
</style>
