<template>
  <div>
    <v-overlay :value="processando">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "Processamento",
  props: {processando: Boolean},

};
</script>

<style>
</style>